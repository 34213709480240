import initUmamiAnalytics from "./umamiAnalytics";
import initGoogleAnalytics from "./googleAnalytics";
import initPoupup from "./popup";
import initForms from "./forms";
import initEventsCalendar from "./eventsCalendar";
import initSitePostsWidget from "./sitePostsWidget";
import initInstagramWidget from "./intagramWidget";

function init() {
  initUmamiAnalytics();
  initGoogleAnalytics();
  initForms();
  initPoupup();
  initInstagramWidget();
  initEventsCalendar();
  initSitePostsWidget();
}

export default init;
